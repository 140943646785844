<template>
  <div>
    <ClientList />
  </div>
</template>

<script>
import ClientList from "@/components/clients/ClientList"

export default {
  name: "Clients",
  components: {
    ClientList,
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.gutter-box {
  margin-top: 5px;
}
.row-header {
  text-align: center;
}
</style>
